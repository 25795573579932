.tabs {
  &__header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;

    &__item {
      padding: rem(20px 26px);
      padding-right: 0;
      margin: 0;
      font-weight: 500;
      color: $grey-500;
      &--disabled {
        color: $light-grey-500;
        pointer-events: none;
      }
      &--active {
        border-bottom: none;
        color: $primary-color;

        .tabs__label__content__circle {
          border: 1px solid $primary-color;
          color: white;
          background-color: $primary-color;
        }
      }
      &:last-of-type {
        .tabs__label__content__icon {
          display: none;
        }
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 10px;

      &__circle {
        width: 22px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $light-grey-500;
        color: $light-grey-500;
        margin-right: 6px;
      }
      &__icon {
        color: $grey-100;
      }
    }
  }
  &__content {
    margin-left: auto;
    padding-right: rem(20px);
    padding-left: rem(10px);
  }
}
