$badge-colors: (primary, danger, success);

.badge {
  $self: #{&};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: $border-radius;
  transition: 0.2s;

  .full-width {
    display: flex;
  }

  &__text {
    flex: 1;

    display: inline-flex;
    align-items: center;
  }

  padding: 0.25em 0.75em;
  &__space {
    padding-left: 0.25em;
  }
  &.round {
    // border radius will be equal for each side anyway
    border-radius: 100px;
  }
  &.sm {
    font-size: 0.8rem;
    padding: 0.125em 0.5em;
    #{$self}__space {
      padding-left: 0.15em;
    }
  }
  &.lg {
    padding: 0.5em 1em;
    #{$self}__space {
      padding-left: 0.35em;
    }
  }

  &.fill {
    @each $col in $badge-colors {
      &.#{$col} {
        background-color: map-deep-get($colors, $col, 500);
        color: white;
      }
    }
  }
  &.outline {
    border: 1px solid;
    @each $col in $badge-colors {
      &.#{$col} {
        border-color: map-deep-get($colors, $col, 500);
        color: map-deep-get($colors, $col, 600);
      }
    }
  }
  &.subtle {
    @each $col in $badge-colors {
      &.#{$col} {
        background-color: map-deep-get($colors, $col, 100);
        color: map-deep-get($colors, $col, 600);
      }
    }
  }
  &.secondary {
    &.fill {
      background-color: map-deep-get($colors, secondary, 500);
      color: white;
    }
    &.subtle {
      background-color: $light-grey-300;
      color: black;
    }
    &.outline {
      border-color: $light-grey-400;
      color: black;
    }
  }
}
