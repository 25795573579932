.icon-active {
  svg.ebs-icon {
    color: $primary-color;
  }
}

.ebs-action__tooltip-icon {
  color: $dark-grey-400;
}

.upload__container {
  margin-top: 0;
}

.ebs-tooltip__wrapper {
  z-index: 10000;
}

.ebs-icon {
  color: inherit;
}

.ebs-notify__item-close {
  display: none;
}

.ebs-loader {
  z-index: inherit;
}

.ebs-card {
  border: none;
}

.ebs-datepicker.has-error {
  border-color: $border-color;
}
.ebs-alert .ebs-icon {
  margin-left: rem(10px);
}
