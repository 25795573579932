.quill-editor {
  .ql-toolbar.ql-snow {
    border: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .ql-container.ql-snow {
    border: none;
    max-height: 50vh;
    overflow: auto;
  }

  .ql-toolbar,
  .ql-editor,
  .ql-blank {
    background: #fff;
    color: #000;
    white-space: normal !important;
  }

  .ql-snow .ql-picker.ql-size {
    width: 60px;
  }

  #quill-editor {
    height: 375px;
    box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.75);
  }

  .ql-showHtml {
    margin-right: 8px;
  }

  .ql-showHtml:after {
    content: "<html>";
  }

  .ql-picker.ql-select-table {
    width: 160px;
  }

  .ql-picker.ql-select-table .ql-picker-item::before,
  .ql-picker.ql-select-table .ql-picker-label::before {
    content: "Custom";
  }

  .ql-picker.ql-select-table [data-value="1"]::before {
    content: "Adaugă tabel";
  }

  .ql-picker.ql-select-table [data-value="2"]::before {
    content: "Adauga un rând jos";
  }
  .ql-picker.ql-select-table [data-value="3"]::before {
    content: "Adauga un rând sus";
  }

  .ql-picker.ql-select-table [data-value="4"]::before {
    content: "Adaugă o coloană la dreapta";
  }
  .ql-picker.ql-select-table [data-value="5"]::before {
    content: "Adaugă o coloană la stânga";
  }

  .ql-picker.ql-select-table [data-value="6"]::before {
    content: "Șterge rândul selectat";
  }
  .ql-picker.ql-select-table [data-value="7"]::before {
    content: "Șterge coloana selectată";
  }
  .ql-picker.ql-select-table [data-value="8"]::before {
    content: "Șterge tabelul";
  }

  .ql-snow .ql-picker-label::before {
    line-height: initial;
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
