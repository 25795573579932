.surface {
  background-color: white;
  border-radius: $border-radius;
  @include elevation-2;

  > section {
    padding: 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }
}
