.attachments-modal {
  &__upload {
    border: 1px dashed $grey-300;
    border-radius: $border-radius;
    padding: 3rem;

    text-align: center;
  }
  &__list {
    h4 {
      max-width: 360px;
    }
  }
  &__list-item {
    display: grid;
    grid-template-columns: auto auto 4fr 1fr auto 20px;
    gap: 1rem;
    place-items: center stretch;

    padding: 0.5rem 1rem;
    border-bottom: 1px solid $light-grey-300;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background-color: $light-grey-200;
    }
  }
}

.attachment-image {
  &__wrapper {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    position: relative;
  }

  &__file-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }
}
