.top-bar {
  svg {
    color: $grey-100;
    &:hover {
      color: $primary-color;
    }
  }

  &__tooltip {
    &__item {
      @include flex-center-vert;
      width: 125px;
      color: $text-color;
      font-weight: 500;
      padding: 0.5rem 0;
      cursor: pointer;

      transition: $transition-time;
      .ebs-icon {
        transition: $transition-time;
      }

      svg {
        color: $grey-100;
        margin-right: rem(7px);
      }

      &:hover {
        color: $primary-color;
        svg {
          color: $primary-color;
        }
      }
    }
  }

  .ebs-avatar__inline-alt {
    margin-left: 7px;
    font-weight: 500;
  }
}
