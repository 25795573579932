.application-state-roadmap {
  $self: #{&};

  $color: $orange;

  $dot-offset: 10px;
  $dot-size: 8px;

  &__grid {
    padding-left: $dot-offset + $dot-size;
  }

  &__title-dot,
  &__badge-dot {
    display: block;
    position: absolute;

    top: 50%;

    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;

    background-color: $grey-300;
  }
  &__title-dot {
    left: -$dot-offset;
    transform: translate(-$dot-size / 2 + 1px, -50%);
  }
  &__badge-dot {
    left: 0px;
    background-color: $color;
    transform: translate(-50%, -50%);
  }

  &__badge {
    position: relative;
    word-break: break-word;
  }

  &__title-wrapper {
    word-break: break-word;
    &--active {
      color: $color;

      #{$self}__title-dot {
        background-color: $color;
      }
    }
  }

  &__title-first-part {
    position: relative;
  }

  &__connection {
    position: absolute;
    border-left: 1px solid $color;
    border-bottom: 1px solid $color;
    border-bottom-left-radius: $dot-offset / 2;

    height: 0px;
    width: $dot-offset;
    left: -$dot-offset;
    top: 50%;

    pointer-events: none;

    transition: 0.2s;
  }

  &__collapse-header.ebs-collapse__header--collapsed &__connection {
    opacity: 0;
    height: 0px;
  }
}
