.sidebar {
  .ebs-sidebar__item:hover:not(.has-options),
  .ebs-sidebar__item--active:not(.has-options) {
    background-color: #f1fdfd;
    color: $primary-color;
    border-left: 3px solid #01689a;
  }

  .ebs-sidebar__item:hover:not(.has-options) .ebs-sidebar__text,
  .ebs-sidebar__item:hover:not(.has-options) .ebs-icon,
  .ebs-sidebar__item--active:not(.has-options) .ebs-sidebar__text,
  .ebs-sidebar__item--active:not(.has-options) .ebs-icon {
    color: $primary-color;
  }

  .ebs-sidebar__text,
  .ebs-sidebar__item .ebs-icon {
    color: $grey-400;
  }

  .ebs-sidebar {
    border-right: 1px solid $light-grey-200;
    width: 250px;
  }

  .ebs-layout__top-bar {
    border-bottom: 1px solid $light-grey-200;
  }

  .ebs-layout__top-bar-title::after {
    border-right: 1px solid $light-grey-200;
    width: rem(59px);
  }

  .ebs-layout__content-wrapper,
  .ebs-layout__footer {
    width: calc(100% - 250px);
  }

  .ebs-layout__top-bar-title {
    min-width: 15rem;
    font-size: 14px;
    color: $primary-color;
  }

  .ebs-layout__content,
  .ebs-layout__container {
    background-color: $light-grey-100;
  }

  @media (max-width: 54.84714em) {
    .ebs-layout__content-wrapper,
    .ebs-layout__footer {
      width: 100%;
    }
  }
}
