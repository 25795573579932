@for $i from 1 through 9 {
  .fw-#{$i}00 {
    font-weight: $i * 100;
  }
}

.color-gray,
.color-grey {
  color: $grey-500;
}
.color-light-gray {
  color: $grey-100;
}
.color-mid-gray {
  color: $grey-400;
}

.color-primary {
  color: $primary-color;
}

.color-danger {
  color: $danger-color;
}

.text-center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.uppercase {
  text-transform: uppercase;
}

.transition {
  transition: 0.2s;
}

.nowrap {
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}

.text-justify {
  text-align: justify;
}

.d-n {
  position: absolute;
  top: -9999px;
}
