.ebs-sidebar {
  .ebs-tooltip__trigger {
    margin: 0;
    padding: 0;
  }
}
.ebs-sidebar__item {
  transition: 0.2s;
  .ebs-sidebar__text {
    padding: 1.14286rem 1.96429rem 1.14286rem 4.10714rem !important;
  }
  .ebs-icon {
    transition: 0.2s;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: background-color 0.2s, width 0.05s;
    width: 0px;
    background-color: #01689a;
  }
}

.ebs-sidebar__item:hover:not(.has-options) {
  background-color: white;
  color: $primary-color;
}

.ebs-sidebar__item--active:hover:not(.has-options),
.ebs-sidebar__item--active:not(.has-options) {
  background-color: #f1fdfd;
  color: $primary-color;

  &::after {
    width: 3px;
  }
}

.ebs-sidebar__item:hover:not(.has-options) .ebs-sidebar__text,
.ebs-sidebar__item:hover:not(.has-options) .ebs-icon,
.ebs-sidebar__item--active:not(.has-options) .ebs-sidebar__text,
.ebs-sidebar__item--active:not(.has-options) .ebs-icon {
  color: $primary-color;
}

.ebs-sidebar__text,
.ebs-sidebar__item .ebs-icon {
  color: $grey-400;
}

.ebs-sidebar:not(.ebs-sidebar__mobile--closed) {
  border-right: 1px solid $light-grey-200;
  width: 250px;
}

.ebs-layout__top-bar {
  border-bottom: 1px solid $light-grey-200;
}

.ebs-layout__top-bar-title::after {
  border-right: 1px solid $light-grey-200;
  width: 1px;
}

// .ebs-layout__content-wrapper,
// .ebs-layout__footer {
//   // width: calc(100% - 250px);
// }

.ebs-layout__top-bar-title {
  min-width: 15rem;
  font-size: 14px;
  color: $primary-color;
}

.ebs-layout__content,
.ebs-layout__container {
  background-color: $light-grey-100;
}

.ebs-sidebar--toggled {
  width: 4.07143rem;
}

.ebs-layout__container .ebs-layout__content {
  height: unset;
  overflow-x: auto;
  padding-bottom: rem(100px);
}

.ebs-layout__content-wrapper {
  min-height: calc(100vh - #{$layout-top-bar-height});
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

@media (max-width: 1200px) {
  .ebs-sidebar:not(.ebs-sidebar__mobile--closed) {
    width: 224px;
  }
  // .ebs-layout__content-wrapper,
  // .ebs-layout__footer {
  //   // width: calc(100% - 220px);
  // }
}

@media (max-width: 54.84714em) {
  .ebs-layout__top-bar-mobile--toggler {
    border-right: 1px solid $light-grey-100;
  }
}

@media (max-width: 54.84714em) {
  .ebs-layout__content-wrapper,
  .ebs-layout__footer {
    width: 100%;
  }
}

.ebs-layout__top-bar-left,
.ebs-layout__top-bar-right {
  white-space: normal;
}

.menu-item-notification {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $danger-color;
  color: #fff;
  display: inline-flex;
  font-size: 9px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: absolute;
  right: 20px;
}
