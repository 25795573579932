.notifications {
  &__trigger {
    top: 12px;
    z-index: 2;
  }

  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }

  &__surface {
    width: 390px;
    background-color: white;
    border-radius: $border-radius;

    position: fixed;
    right: 13rem;
    top: 3.5rem;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s, opacity 0.07s;

    z-index: 4;

    &--open {
      opacity: 1;
      transform: initial;
      pointer-events: initial;
    }
  }

  &__header {
    padding: 15px;
    border-bottom: 1px solid $light-grey-200;
    &-title {
      text-transform: uppercase;
      align-self: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: auto;
    svg {
      color: $grey-100;
      &:hover {
        color: $primary-color;
      }
    }
  }

  &__item {
    padding: 10px 15px 0;

    &--unread:hover {
      cursor: pointer;
    }

    .icon {
      cursor: pointer;
      transition: all 0.3s;
    }

    &:not(:first-child) {
      border-top: 1px solid $light-grey-200;
    }

    &-date {
      font-weight: 400;
      color: $grey-200;
    }

    &-title {
      padding-bottom: 5px;
      color: $primary-color;
    }
  }

  // Badge
  &__badge .ebs-badge__container {
    min-width: rem(20px);
    width: auto;
    border-radius: 15px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 1;
  }
}
