.chat-message {
  $self: #{&};

  margin-bottom: 1rem;
  max-width: calc(min(420px, 100% - 1rem));
  min-width: 120px;

  &__content {
    padding: 0.75rem 1rem;
    border-radius: $border-radius;
    position: relative;
    overflow: hidden;
    word-break: break-word;
  }

  &--sent {
    margin-left: auto;
    #{$self}__content {
      background-color: $primary-color;
      color: white;
      border-bottom-right-radius: 0px;
    }
    #{$self}__info {
      text-align: right;
    }
  }
  &--received {
    margin-right: auto;
    #{$self}__content {
      background-color: $light-grey-200;
      border-bottom-left-radius: 0px;
    }
  }
  &__name {
    color: $grey-100;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(16px);

    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }

  &__info {
    color: $grey-300;
    font-size: rem(12px);
    line-height: rem(16px);
    margin-top: 4px;

    &--error {
      color: $danger-color;
    }
  }
}
