.ebs-modal {
  $padding: 1.5rem 2rem;
  margin: 2rem auto;

  &__header {
    padding: $padding;
  }
  &__content {
    padding: $padding;
  }
  &__footer {
    padding: $padding;
  }
}

.ebs-modal__header--close {
  right: 2rem;
  padding: 0.25rem;
}

.ebs-modal__header {
  background: none;
}

.ebs-modal__title {
  font-size: initial;
  font-weight: 600;
  font-size: 1.5rem;
}
