.attachment-item {
  position: relative;
  overflow: hidden;

  border-radius: $border-radius;
  border: 1px solid $light-grey-400;

  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;

  background-color: $light-grey-200;

  &:hover {
    cursor: pointer;
  }

  a {
    flex: 1;
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
    color: $grey-800;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__progress {
    position: absolute;

    left: 0px;
    bottom: 0px;
    width: calc(1% * var(--progress));
    height: 3px;

    transition: 0.2s;

    background-color: $primary-color;
    z-index: 2;
  }

  &--loading {
    background-color: $light-grey-400;
  }
  span {
    color: var(--attachment-color);
  }
}
