.ebs-badge {
  $self: #{&};

  // Animations
  &--pulse {
    &#{$self}__type--danger .ebs-badge__container {
      box-shadow: 0 0 0 0 rgba(247, 102, 89, 1);
      transform: scale(1);
      animation: pulse-red 2s infinite;
    }
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 102, 89, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(247, 102, 89, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(247, 102, 89, 0);
  }
}
