.selectable {
  position: relative;
  cursor: pointer;
  padding: 0.5rem 0.5rem;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: -1rem;
    right: -1rem;

    transition: 0.2s;
    border-radius: $border-radius;

    background-color: transparent;
    z-index: -1;
  }
  &:hover:not(&--selected)::before {
    background-color: $light-grey-200;
  }
  &--selected::before {
    background-color: $light-grey-300;
  }
}
