.dropdown {
  display: inline-block;
  position: relative;

  &__content {
    position: absolute;
    left: 0px;
    top: calc(100% + 0.5rem);
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    pointer-events: none;
    transition: transform 0.2s, opacity 0.07s;
    z-index: 2;

    &--right {
      left: initial;
      right: 0px;
    }
    &--cover {
      top: -10px;
    }
  }

  &:focus-within &__content {
    opacity: 1;
    transform: initial;
    pointer-events: initial;
    min-width: 100%;
  }
}
