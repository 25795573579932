.divider {
  display: block;
  width: 100%;
  border-bottom: 1px solid $light-grey-200;
}

.input-phone {
  &--large {
    .ebs-label {
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      color: #252c32;
      height: 100%;
      margin-bottom: rem(4px);
    }
  }
  .ebs-input__prefix {
    height: 100%;
    line-height: 1;
  }
  .ebs-input__wrapper.has-prefix .ebs-input {
    padding-left: rem(48px);
  }
}

.icon-btn.ebs-button--small .ebs-button {
  padding: 0 1px;
}

// Realtive form field
.ebs-form__item--relative {
  position: relative;
  .ebs-form__field__label {
    padding-right: 1.5rem;
  }
}

.label-info__tooltip {
  margin-right: 0;
  margin-left: auto;
  // fix line-height
  line-height: 12px;
  position: absolute;
  right: 0px;
  top: 0px;

  &:hover {
    cursor: pointer;

    .ebs-icon path {
      stroke: #01689a;
      transition: all 0.3s linear;
    }
  }
}

.title-tooltip__info,
.form-field__tooltip-info {
  overflow: auto;
  max-width: 400px;
  max-height: 400px;
  font-size: rem(12px);
  line-height: 1rem;
  p {
    text-indent: 10px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      padding: 3px 0 0 10px;
    }
  }
}

.title-tooltip {
  .ebs-tooltip__trigger:hover {
    cursor: pointer;
    path {
      stroke: #01689a;
      transition: all 0.3s linear;
    }
  }
}

.ebs-tooltip__arrow {
  .ebs-icon--arrow-top {
    vertical-align: top;
  }
}

.logo {
  color: #01689a;
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 5px;

  svg {
    width: 51px;
    height: 42px;
  }
}

.radio-field {
  .ebs-radio__group {
    display: inline-flex;

    .ebs-radio__wrapper {
      padding: 14px 50px;
      border: 1px solid $light-grey-200;
      border-radius: 6px;
      margin-bottom: 0;
      margin-right: 15px;
      width: 182px;
      height: 37px;
      transition: 0.2s;

      &:hover {
        border-color: #b0babf;
      }
      &:focus-within {
        border-color: #5b6871;
      }
    }
    .ebs-radio__wrapper.has-text .ebs-radio {
      padding: 8px;
      left: 8px;
      top: 8px;
    }

    .ebs-radio__wrapper.disabled {
      background-color: $light-grey-100;
      &:hover {
        border-color: $light-grey-200;
      }
    }

    .ebs-radio__text {
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }

  &.vertical {
    .ebs-radio__group {
      flex-direction: column;
      width: 100%;

      .ebs-radio__wrapper {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.bordered {
  border: 1px solid $light-grey-200;
}

.bordered-icon {
  border-radius: $border-radius;
  border: 1px solid $light-grey-200;
  padding: 0.1em;
  display: flex;

  &.success {
    color: $success-color;
  }
  &.error {
    color: $danger-color;
  }
}
.progress {
  border-radius: $border-radius;
  border: 1px solid $light-grey-200;
  padding: 0.1em 0.5em 0.1em 0.1em;
  display: flex;
  white-space: nowrap;
  min-width: 100px;
  justify-content: space-between;

  &__delimiter {
    margin: 0 2px;
    color: $light-grey-200;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin-left: 2px;
    }
    &--success {
      color: $success-color;
    }
    &--error {
      color: $danger-color;
    }
  }
}

.container-card {
  .ebs-card {
    max-width: 830px;

    &__header {
      border-bottom: 1px solid $light-grey-200;
    }
    &__footer {
      border-top: 1px solid $light-grey-200;
    }
  }
}

@media (max-width: 1200px) {
  .container-card .ebs-card {
    max-width: 100%;
  }
}

// extra small
.btn-xsm.ebs-button__wrapper.ebs-button--round .ebs-button,
.btn-xsm .ebs-button {
  padding: 0rem;
}

.input-hide-value {
  input,
  textarea {
    color: transparent !important;
  }
}

.bordered-checkbox-wrapper {
  padding: 0.5rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.normal-textarea-container {
  padding: 0.5rem 0;
  border: 1px solid $light-grey-400;
  border-radius: $border-radius;
  background-color: white;

  transition: 0.2s;
  &:hover {
    border-color: #b0babf;
  }
  &:focus-within {
    border-color: #5b6871;
  }

  textarea {
    border: none;
    resize: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.badge.orange {
  color: $orange;
  border-color: $orange;
}

.ebs-action__tooltip-item {
  a {
    color: $grey-500;
    &:hover {
      color: $primary-color;
    }
  }
}

.risk-level-low {
  color: $success-color;
  text-transform: capitalize;
}
.risk-level-medium {
  color: $warning-color;
  text-transform: capitalize;
}
.risk-level-high {
  color: $danger-color;
  text-transform: capitalize;
}

.status-color-success {
  &--text {
    color: $success-color;
  }
  background-color: $success-color;
}
.status-color-warning {
  &--text {
    color: $warning-color;
  }
  background-color: $warning-color;
}
.status-color-danger {
  &--text {
    color: $danger-color;
  }
  background-color: $danger-color;
}

.cell-checkbox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

// Remove tooltip animation
.ebs-tooltip__body {
  animation-fill-mode: none !important;
}

.cke {
  border-radius: 6px;
  overflow: hidden;
}
