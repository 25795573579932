.pdf-viewer {
  max-width: 850px;
  margin: 0 auto;

  .react-pdf__Page {
    box-shadow: 0 0 24px -5px rgba(62, 62, 62, 0.3);
  }

  &__page-input {
    display: inline-block;
    width: 44px;
    input {
      &::-webkit-inner-spin-button {
        appearance: none;
      }
      &[type="number"] {
        appearance: none;
      }
    }
  }
}
