.chat-message-form {
  &__input-container {
    position: relative;

    textarea {
      min-height: 5rem;
      padding-right: 3rem;
    }
  }
  &__attachments {
    display: grid;
    padding: 0.5rem;
    gap: 0.5rem;

    max-height: 150px;
    overflow: auto;

    &--empty {
      padding: 0;
    }
  }
  &__input-btn {
    position: absolute;
    right: 0.75rem;

    padding: 0rem !important;
    .ebs-button {
      padding: 0rem !important;
    }
  }

  &__input-send {
    bottom: 2.75rem;
    &,
    &:hover,
    &:active {
      .ebs-icon {
        color: $primary-color;
      }
    }
  }
  &__input-attachment {
    bottom: 0.5rem;
  }
}
