.view-pdf-modal {
  .ebs-modal {
    margin-top: 3rem;
  }
  .ebs-modal__content {
    max-height: 75vh;
    overflow-y: auto;
    > p {
      height: 400px;
    }
  }
}
