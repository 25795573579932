.login-with-mpass-link-button {
  background: $grey-50;
  color: $grey-700;
  font-weight: 600;

  border-radius: $border-radius;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  padding: 12px 24px;

  transition: 0.2s;

  &:hover {
    background-color: $grey-100;
  }

  &__logo {
    height: 26px;
  }
}
