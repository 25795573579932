.badge-tabs {
  &__header {
    display: flex;
    &__item {
      margin: 0 10px 0 0;
      transition: 0.2s ease-in-out;

      &--active {
        background: $light-grey-300;
        border-radius: 6px;
        color: $text-color;
      }
      &:hover {
        background: $light-grey-300;
        border-radius: 6px;
      }
    }
  }
  &__label {
    background: none;
    border-radius: 6px;
    padding: 4px 12px;
    color: $text-color;
  }
}
