.filters {
  &__tooltip-trigger {
    width: 170px;
  }

  &__tooltip {
    &:not(&--visible) {
      pointer-events: none;
      * {
        pointer-events: none !important;
      }
    }

    transition: 0.2s;
  }

  &__tooltip-content {
    opacity: 0;
    transform: translateY(-10px);
    width: 320px;
    transition: 0.1s;
  }

  &__tooltip--visible &__tooltip-content {
    opacity: initial;
    transform: initial;
  }
}

.list-filter-container {
  max-height: 200px;
  overflow: auto;
}
