.ebs-input__wrapper .ebs-icon {
  color: $grey-100;
  &:hover {
    color: $primary-color;
  }
}

.ebs-input__search-wrapper .ebs-input__container .ebs-input__clear {
  right: rem($gutter-size);
}

.ebs-input__search.has-suffix .ebs-input__container .ebs-input__clear {
  right: rem($gutter-size * 2);
}

.ebs-select {
  border: 1px solid $light-grey-400;

  &--large {
    min-height: 2.5rem;
    font-weight: 400;
  }
}

.ebs-datepicker {
  font-weight: 400;
  border-color: $light-grey-400;

  svg {
    color: $light-grey-200;
  }
  &--large {
    min-height: 2.5rem;
  }

  &__wrapper {
    .react-datepicker__input-container::before {
      pointer-events: none;
    }
    .react-datepicker__close-icon {
      padding-right: rem(7px);

      &::after {
        padding: 1px;
      }
    }
  }
}

.ebs-select__wrapper .ebs-icon {
  color: $grey-400;
}

.ebs-select__wrapper {
  outline: none;
}

.ebs-select,
.ebs-input__wrapper,
.ebs-select__wrapper.active .ebs-select-dropdown__wrapper .ebs-select,
.ebs-input__wrapper--unactive {
  border-color: $light-grey-400;
}

.ebs-input__wrapper,
.ebs-datepicker,
.ebs-textarea {
  transition: 0.2s;
  &:hover {
    border-color: #b0babf;
  }
  &:focus-within {
    border-color: #5b6871;
  }
}

.ebs-select__wrapper.has-error .ebs-select {
  border-color: $danger-color;
}

.ebs-select__wrapper,
.ebs-select__wrapper.active .ebs-select-dropdown__wrapper {
  .ebs-select {
    transition: 0.2s;
  }
  &:hover .ebs-select {
    border-color: #b0babf;
  }
  &:focus,
  &:focus-within {
    .ebs-select {
      border-color: #5b6871;
    }
  }
}

.ebs-input__wrapper.disabled,
.ebs-textarea.disabled,
.ebs-datepicker:disabled,
.ebs-datepicker:disabled:hover,
.ebs-datepicker:disabled:focus,
.ebs-datepicker:disabled:active,
.ebs-datepicker:disabled .active,
.ebs-select__wrapper.disabled .ebs-select-dropdown__wrapper .ebs-select {
  background-color: $light-grey-100;
  border-color: $light-grey-200;
  cursor: not-allowed;
}

.ebs-textarea {
  transition: 0.2s, height 0s;
  resize: vertical;
}

.ebs-select__options > .ebs-select__options-items {
  max-height: 300px;
}

.fake-input {
  box-sizing: content-box;
  line-height: 1.5;
  min-height: 1.5em;
  word-break: break-all;
  width: unset;
  padding-right: 3rem;
}
