.ebs-button--large .ebs-button {
  font-weight: 400;
}

.ebs-button {
  &__wrapper.ebs-button--round {
    border-radius: 50px;
  }
  &--ghost.ebs-button__wrapper {
    background-color: white;
    border-color: $light-grey-400;
    color: black;

    .ebs-button,
    .ebs-icon {
      color: black;
    }

    &:hover {
      border-color: $light-grey-400;
      background-color: $light-grey-300;
      color: black;

      .ebs-button,
      .ebs-icon {
        color: black;
      }
    }

    &:active,
    &.active {
      border-color: transparent;
      background-color: $light-grey-400;
      color: black;

      .ebs-button,
      .ebs-icon {
        color: initiial;
      }
    }

    .ebs-icon {
      color: black;
    }
  }

  &--ghost & {
    color: black;
  }

  &--text {
    background-color: transparent;
    color: black;

    &:hover {
      background-color: $light-grey-200;

      .ebs-button,
      .ebs-icon {
        color: black;
      }
    }

    &:active,
    &.active {
      background-color: $light-grey-400;

      .ebs-button,
      .ebs-icon {
        color: black;
      }
    }

    .ebs-icon {
      color: black;
    }
  }

  &--text & {
    color: black;
  }

  &__wrapper:focus-within {
    box-shadow: 0px 0px 0px 2px $primary-200;
  }
  &__group &__wrapper:focus-within {
    z-index: 2;
  }

  &--ghost:focus-within,
  &--text:focus-within {
    box-shadow: 0px 0px 0px 2px $light-grey-400;
    border-color: transparent;
  }
}

.ebs-sort-by__wrapper .ebs-button--ghost .ebs-button .ebs-icon {
  color: black;
}

.ebs-sort-by__wrapper:hover .ebs-button--ghost .ebs-button .ebs-icon {
  color: black;
}

.ebs-button--ghost.ebs-button__wrapper:active .ebs-button,
.ebs-button--ghost.ebs-button__wrapper:active .ebs-icon,
.ebs-button--ghost.ebs-button__wrapper.active .ebs-button,
.ebs-button--ghost.ebs-button__wrapper.active .ebs-icon {
  color: black;
}
