.ebs-radio__group .ebs-radio__input:checked:hover ~ .ebs-radio,
.ebs-radio__group .ebs-radio__input:hover ~ .ebs-radio {
  background-color: $light-grey-100;
  border-color: $light-grey-200;
}

.ebs-radio__dot {
  transition: 0.05s;
  border-width: 0px solid $primary-color;
}

.ebs-radio__group .ebs-radio__input:checked ~ .ebs-radio .ebs-radio__dot {
  border-width: 4px;
  background-color: white;
  box-shadow: none;
}
