.background-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 30px;

  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(0, 0, 0, 0)
  );

  transition: 0.2s;
  opacity: 0;

  pointer-events: none;
  animation: none;
  display: none;

  &--loading {
    display: initial;
    animation: background-shift-loading 1.2s cubic-bezier(0.53, 0.09, 0.45, 0.9)
      infinite alternate;
  }
}

@keyframes background-shift-loading {
  0% {
    left: -10%;
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    left: 90%;
    opacity: 0;
  }
}
