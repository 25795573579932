.carousel {
  height: 100%;
  position: relative;

  &__item {
    transition: 0.2s;
    position: absolute;
    width: calc(1 / var(--count) * 100% - (var(--count) - 1) * var(--gap));
    transform: translateX(
      calc((var(--index) - var(--offset)) * (100% + var(--gap)))
    );
    height: 100%;
  }
}
