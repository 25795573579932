.confirmation {
  &__content {
    margin: 90px 0;

    &-body {
      margin-top: rem(50px);
      font-size: 16px;
    }
    &-item {
      color: $grey-200;
      margin-left: 8px;
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 2px solid $light-grey-200;
    padding: 18px;
    width: 100%;
  }
}
