.aeo-table {
  $self: #{&};

  overflow: hidden;
  border-radius: $border-radius;
  background-color: #fff;

  table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
  }

  &-cell {
    padding: 0.5em 0;
    padding: 1rem 1.5rem;
    position: relative;
  }

  &-row {
    position: relative;
    border-bottom: 1px solid $light-grey-200;
    transition: 0.1s;

    &:last-child {
      border-bottom: none;
    }
  }

  &-thead {
    text-align: left;
    background-color: $light-grey-100;
    #{$self}-cell {
      font-weight: 400;
      padding: 1.5rem 1.5rem;
      white-space: nowrap;
    }
  }

  &--has-title {
    #{$self}-title {
      padding: 1rem 1.5rem;
    }
  }

  &--has-footer {
    #{$self}-footer {
      padding: 20px;
      font-weight: 400;
    }
    #{$self}-row:last-child {
      border-bottom: 1px solid $light-grey-200;
    }
  }

  &--bordered {
    border: 1px solid $light-grey-200;
  }

  &--stripped {
    #{$self}-row:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--stripped-even {
    #{$self}-row:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--black-header {
    #{$self}-thead {
      background-color: rgba(0, 0, 0, 0.843);
      color: white;
    }
  }

  &--dense {
    #{$self}-thead #{$self}-cell {
      padding: 1rem 1rem;
    }
    #{$self}-cell {
      padding: 0.5rem 1rem;
    }
  }

  &--highlight-hover {
    #{$self}-row {
      cursor: pointer;
    }
    #{$self}-row:hover {
      background-color: $light-grey-200;
    }
  }

  //   &--elevated {
  //     box-shadow: $box-shadow;
  //   }
  &__status-circle {
    vertical-align: middle;
    border-radius: rem(4px);
    min-height: rem(8px);
    min-width: rem(8px);
    display: inline-block;
    margin-right: rem(8px);
  }
  &-cell-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-row-expand-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    color: #aaa;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid;
    cursor: pointer;
  }
}
