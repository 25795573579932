.chat {
  $self: #{&};

  &__title {
    text-transform: uppercase;
    font-size: rem(14px);
    line-height: rem(24px);
  }
  &__close {
    cursor: pointer;
  }
  &__info-box {
    background: $light-grey-200;

    p:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  &__trigger {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 2;
    border-radius: 100px;
    box-shadow: 0px 2px 5px $grey-400;
  }

  // Chat new messages number
  &__badge {
    .ebs-badge__container {
      left: -5px;
      font-size: 12px;
    }
  }

  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }

  &__surface {
    background-color: white;
    border-radius: $border-radius;

    @include elevation-3;

    position: fixed;
    right: 8rem;
    bottom: 2rem;

    width: 360px;
    height: calc(100vh - 4rem);
    max-height: 700px;

    opacity: 0;
    transform: translateY(2rem);
    transition: 0.2s;
    pointer-events: none;

    z-index: 4;

    display: grid;
    grid-template-rows: auto auto 1fr;

    &--open {
      opacity: 1;
      transform: initial;
      pointer-events: initial;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  &__messages-wrapper {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }

  &__empty-messages-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  &__messages-list {
    overflow: auto;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
  }
}
