.saq-grid {
  $left: 830px;
  $right: 450px;
  $gap: 1.5rem;

  display: grid;
  align-items: start;
  grid-template-columns: auto $right;
  gap: $gap;

  max-width: calc(#{$left} + #{$gap} + #{$right});

  overflow: auto;
}

.saq-tabs {
  $self: #{&};
  $nr-size: 21px;
  $dot-size: 10px;

  &__number {
    flex-shrink: 0;
    flex-grow: 0;
    width: $nr-size;
    height: $nr-size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;

    border-radius: 50%;
    transition: 0.2s;
    border: 1px solid $grey-200;
    color: $grey-200;
  }

  &__subitem:hover &__number {
    background-color: $light-grey-300;
  }

  &__item--success &__number,
  &__subitem--success &__number {
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px $success-600;
  }
  &__item--error &__number,
  &__subitem--error &__number {
    border-color: transparent;
    box-shadow: 0px 0px 0px 2px $danger-500;
  }
  &__item.active &__number,
  &__subitem.active &__number {
    border-color: $primary-color;
    background-color: $primary-color;
    color: white;
  }

  &__dot {
    flex-shrink: 0;
    flex-grow: 0;
    width: $dot-size;
    height: $dot-size;
    display: inline-flex;
    border-radius: 50%;
    transition: 0.2s;
    background-color: $grey-200;
  }
  &__line {
    background-color: $grey-100;
    flex-shrink: 0;
    flex-grow: 0;
    width: 10px;
    height: 1px;
    display: inline-flex;
    transition: 0.2s;
  }

  &__subitem:hover &__dot {
    background-color: $light-grey-300;
  }

  &__item--success &__dot,
  &__subitem--success &__dot {
    background-color: $success-600;
  }
  &__item--error &__dot,
  &__subitem--error &__dot {
    background-color: $danger-500;
  }
  &__item.active &__dot,
  &__subitem.active &__dot {
    background-color: $primary-color;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
    transition: 0.2s;
    border-radius: $border-radius;
    color: black;

    &:hover {
      background-color: $light-grey-100;
    }

    &--static {
      cursor: initial;
      #{$self}__number {
        border-color: transparent;
      }
      &:hover {
        background-color: transparent;
      }
      &:hover #{$self}__number {
        background-color: transparent;
      }
    }
  }

  &__subitem-list {
    position: relative;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, $nr-size);
    padding-left: calc(#{$nr-size} + 1rem);
    margin-bottom: 0.5rem;

    &:before {
      content: "";
      position: absolute;
      left: calc(#{$nr-size} / 2 + 0.5rem);
      bottom: 0.75em;
      width: $nr-size / 2;
      height: 1.25em;
      border-left: 1px solid $light-grey-400;
      border-bottom: 1px solid $light-grey-400;
    }
  }
  &__subitem {
    display: inline-block;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .saq-grid {
    grid-template-rows: unset;
    grid-template-columns: unset;
  }
  .saq-tabs {
    grid-row-start: 1;
  }
}
