.ebs-checkbox {
  border-radius: 3px;
  height: 1.2rem;
  width: 1.2rem;

  &::before {
    background-color: transparent !important;
  }
}

.ebs-checkbox__input:checked ~ .ebs-checkbox .ebs-checkbox__check {
  font-size: 0.9rem;
}

$checkbox-d-path: path("M6 18L13 24L26 10");

@supports (d: $checkbox-d-path) {
  .ebs-checkbox__check path {
    d: $checkbox-d-path;
    stroke-width: 6;
    transform: none;
    fill: none;
  }
}

.ebs-checkbox__input:hover ~ .ebs-checkbox {
  border-color: #b0babf;
}

.ebs-checkbox__wrapper {
  &:hover .ebs-checkbox {
    border-color: #b0babf;
  }
  &:focus-within .ebs-checkbox {
    box-shadow: 0px 0px 0px 2px $primary-200;
    border-color: transparent;
  }
}
