.check-cross-icon {
  display: inline-flex;
  border-radius: 100px;
  padding: 0.25rem;

  background-color: $danger-color;
  color: white;

  &.checked {
    background-color: $success-color;
  }
}
