.ebs-sort-by__tooltip-items .ebs-sort-by__tooltip-item .ebs-icon {
  position: initial;
  right: initial;
  top: initial;
  transform: initial;
}

.ebs-sort-by__tooltip {
  .ebs-sort-by__check-circle {
    display: inline-block;
    width: rem(16px);
    height: rem(16px);
    border-radius: 50%;
    border: 1px solid $grey-100;
    transition: 0.05s;
  }

  &-title {
    font-size: 14px;
  }

  &-items &-item {
    padding: 1rem 1.5rem;
    color: black;
    &:hover {
      background-color: $light-grey-200;
      color: black;
    }
    &:hover .ebs-sort-by__check-circle {
      border-color: $primary-300;
    }
    &--active {
      color: black;
      font-weight: 500;
      .ebs-sort-by__check-circle {
        border: 4px solid $primary-color;
      }
    }
  }
}

.ebs-sort-by__wrapper {
  position: relative;
  display: flex;

  > .ebs-button__wrapper:not(:last-of-type) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
