.dashboard {
  &__news-card {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  &__notification {
    .notifications__container {
      height: 414px;
    }
  }
  &__news-text {
    height: 250px;
    overflow: auto;
    word-wrap: break-word;
  }
  &__row-2 {
    margin: 0 $mx-minus-26;
    .ebs-collapse {
      border-radius: 0;
    }
  }
  &__row-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
  }
  &__row-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    gap: 10px;

    @media (max-width: $grid-xl-size) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
    .contacts {
      font-weight: 600;
      color: $grey-400;
      &__phone a {
        color: black;
      }
      &__email a {
        color: $primary-color;
      }
    }
  }
  &__row-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-rows: minmax(max-content, 1fr);
    gap: 10px;

    @media (max-width: $grid-xl-size) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
    .ebs-card__header {
      border-bottom: 1px solid $light-grey-200;
    }
    &__authorization {
      &__request-logo {
        background-color: $light-grey-300;
      }
      .ebs-card__body {
        height: calc(100% - 75px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__news {
      &__item {
        padding: 15px;
        border: 1px solid $light-grey-200;
        border-radius: $border-radius;
        &__date {
          color: $grey-400;
          margin-top: 10px;
        }
      }
    }
  }

  &__decisions-table {
    .attachment-image__wrapper {
      width: 3rem;
      height: 3rem;
    }
    .aeo-table-cell {
      padding: 0.5rem 1.5rem;
    }
    .aeo-table-thead .aeo-table-cell {
      padding: 1.5rem;
    }
    .aeo-table-thead {
      display: none;
    }
    .aeo-table-content {
      overflow: initial;
    }
    .aeo-table-row-expand-icon-cell {
      display: none;
    }
    .cke {
      border: none;
    }
  }
}
.view-authorization {
  &__download-btn {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    button.ebs-button {
      display: inline;
      width: auto;
      padding-left: 0.5rem;
      padding-right: 0;
    }
    .ebs-button__prefix {
      position: initial;
      transform: none;
    }
  }
  &__change-status-btn {
    width: 270px;
  }
}

.editor-form {
  border: 1px solid #ccc;
  border-radius: $border-radius;
  margin-left: 6px;
  overflow: hidden;
  #toolbar {
    border-top: none;
  }
  &__footer {
    border-top: 1px solid #ccc;
    padding-top: 15px;
  }
}
