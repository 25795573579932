$primary-color: #01689a;
$secondary-color: #5b6871;
$success-color: #47d16c;
$warning-color: #f8c51b;
$danger-color: #f76659;

// TODO: Add accent colors for 3
// Accent colors
$accent-color-1: #c479f3;
$accent-color-2: #38d9b9;
$accent-color-3: #fcbf00;

// Primary colors
$primary-100: #c8f6f9;
$primary-200: #93e8f4;
$primary-300: #5ac6e0;
$primary-400: #319cc2;
$primary-500: $primary-color; // main one
$primary-600: #005084;
$primary-700: #003c6e;
$primary-800: #002a59;
$primary-900: #001e49;

$secondary-100: #b0babf;
$secondary-200: #9aa6ac;
$secondary-300: #84919a;
$secondary-400: #6e7c87;
$secondary-500: $secondary-color;
$secondary-600: #425261;
$secondary-700: #2d3e51;
$secondary-800: #1d2c41;
$secondary-900: #111e36;

// Semantic colors
$success-100: #dffcdb;
$success-200: #b9fab8;
$success-300: #91f198;
$success-400: #72e386;
$success-500: $success-color;
$success-600: #33b362;
$success-700: #239658;
$success-800: #16794d;
$success-900: #0d6446;

// semantic warning colors
$warning-100: #fef8d1;
$warning-200: #feefa3;
$warning-300: #fce475;
$warning-400: #fad853;
$warning-500: $warning-color;
$warning-600: #d5a313;
$warning-700: #b2840d;
$warning-800: #8f6608;
$warning-900: #775105;

// semantic danger colors
$danger-100: #feebde;
$danger-200: #fed2bd;
$danger-300: #fcb39b;
$danger-400: #fa9582;
$danger-500: $danger-color;
$danger-600: #d44141;
$danger-700: #b12c37;
$danger-800: #8f1c2f;
$danger-900: #76112a;

// accent 1 colors
$accent-1-100: #fae4fe;
$accent-1-200: #f3cafd;
$accent-1-300: #e7affb;
$accent-1-400: #d99af7;
$accent-1-500: $accent-color-1;
$accent-1-600: #9b58d0;
$accent-1-700: #753cae;
$accent-1-800: #53268c;
$accent-1-900: #3a1774;

// accent 2 colors
$accent-2-100: #d7fde7;
$accent-2-200: #b0fbd7;
$accent-2-300: #87f3c9;
$accent-2-400: #66e8c2;
$accent-2-500: $accent-color-2;
$accent-2-600: #28baab;
$accent-2-700: #1c9c9b;
$accent-2-800: #11747d;
$accent-2-900: #0a5768;

// accent 3 colors
$accent-3-100: #fef7cb;
$accent-3-200: #feed98;
$accent-3-300: #fee065;
$accent-3-400: #fdd33f;
$accent-3-500: $accent-color-3;
$accent-3-600: #d89e00;
$accent-3-700: #b58000;
$accent-3-800: #926300;
$accent-3-900: #784f00;

//grey colors dark
$dark-grey-100: #48535b;
$dark-grey-400: #252c32;
$dark-grey-500: #1a2024;

//grey colors mid
$grey-50: #d7dcde;
$grey-100: #b0babf;
$grey-200: #9aa6ac;
$grey-300: #84919a;
$grey-400: #6e7c87;
$grey-500: #5b6871;
$grey-600: #425261;
$grey-700: #2d3e51;
$grey-800: #1d2c41;
$grey-900: #111e36;

//grey colors light
$light-grey-100: #f6f8f9;
$light-grey-200: #eef0f2;
$light-grey-300: #e5e9eb;
$light-grey-400: #dde2e4;
$light-grey-500: #d5dadd;

$orange: #f68d2e;
$assign :#e6f4ff
