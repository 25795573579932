.circular-progress {
  transition: 0.2s;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    transform: rotate(-90deg);

    circle {
      transition: 0.2s;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5em;
    height: 5em;
    color: var(--color);
  }
}
