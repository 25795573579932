.collapse {
  &__header {
    padding: 0.5em 0;
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      left: -0.5rem;
      width: calc(100% + 1rem);

      border-radius: $border-radius;
      background-color: transparent;
      transition: 0.2s;

      z-index: 0;
    }
    &:hover::before {
      background-color: rgba(0, 75, 90, 0.03);
    }
    &--disabled {
      color: $grey-100;
      cursor: not-allowed;
      h4 {
        color: $grey-100;
      }
    }
  }

  &__content {
    padding: 0;
    overflow: hidden;
    height: 0;
    opacity: 0;
    pointer-events: none;

    transition: 0.1s;

    &.open {
      padding: 0.5em 0;
      height: initial;
      overflow: initial;
      opacity: 1;
      pointer-events: initial;
    }
  }
}
