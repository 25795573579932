.icon-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(5px);
  border: 1px solid $light-grey-200;
  border-radius: $border-radius-sm;
  background: none;
  transition: 0.2s ease-in-out;
  &--rounded {
    border-radius: 50%;
  }
  .ebs-icon {
    display: inherit;
    color: $grey-100;
  }
  &:hover {
    .ebs-icon {
      color: $dark-grey-400;
    }
  }
}
