.other-requests {
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }

  &__surface {
    width: 300px;
    background-color: white;
    border-radius: $border-radius;

    position: fixed;
    right: calc(7% + 5rem);
    bottom: calc(13% + 2rem);

    opacity: 0;
    pointer-events: none;
    transform: scaleY(0);
    z-index: 4;

    &--open {
      opacity: 1;
      transform: initial;
      pointer-events: initial;
    }
  }

  &__container {
    padding: 15px;
    max-height: 80vh;
    overflow: auto;
  }

  &__item {
    padding: 5px 0;
    margin-bottom: 5px;
    cursor: pointer;
    button.ebs-button {
      text-align: left;
      padding: 0;
    }

    &:hover {
      border-color: $light-grey-200;
      background-color: $light-grey-300;
      border-radius: 3px;
      color: black;
    }
  }
}
