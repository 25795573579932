html,
body {
  @include fontRegular;
  margin: 0;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
  padding: 0;
}

p,
span {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

// Fix vertical align for svg
:where(svg) {
  vertical-align: initial;
}


