.auth-layout {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-y: auto;
    height: 100vh;
  }

  &__image {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__logo {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 100%;
    width: calc(100% - 3rem);
    margin: 0 auto;
    max-width: 35vw;
  }

  &__content {
    background-color: #f6f8f9;
  }

  &__content-container {
    max-width: 520px;
    margin: 0 auto;
    padding: 3rem;

    display: grid;
    height: 100%;
  }

  &__content-body {
    place-self: center stretch;
  }

  &__title {
    color: $primary-color;
  }

  &__form {
    width: 360px;
    &__btn {
      width: 100%;
      margin: 10px 0;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }

  &__link {
    color: $primary-color;
    margin-left: 4px;
  }
}

@media (max-width: 1260px) {
  .auth-layout {
    &__container {
      grid-template-columns: 500px 1fr;
    }
  }
}

@media (max-width: 770px) {
  .auth-layout {
    &__image {
      display: none;
    }
    &__container {
      grid-template-columns: 1fr;
    }
  }
}
